@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}
html,
body,
#root {
  width: 100%;
  height: 100%;

}